import styled from 'styled-components';
import React, { useState } from 'react';
import { loadStripe } from "@stripe/stripe-js"

const PSButton = styled.a`
  background: var(--white);
  border: 2px solid #000;
  padding: 15px 20px;
  display: inline-block;
  text-align: center;
  color: var(--black);
  text-decoration: none;
  box-shadow: #000 -10px 8px 0px;
  letter-spacing: 2px;
  font-size: 20px;
  min-width: 150px;
  text-align: center;
  font-family: 'roboto-mono';
  margin-top: 10px;
  &:hover {
    background: #fff;
    box-shadow: none;
    color: var(--black);
    cursor: pointer;
  }
`

const loadingStyles = {
  opacity: '0.7'
}

let stripePromise
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(`${process.env.GATSBY_STRIPE_KEY}`)
  }
  return stripePromise
}

const Button = ({fireGTMEvent, program, location, text}) => {
  const [loading, setLoading] = useState(false)

  const checkOut = async () => {
    const stripe = await getStripe()

    const url = program === 'full' ? `${process.env.GATSBY_STRIPE_SERVER_ROOT}/deposit-ft` : `${process.env.GATSBY_STRIPE_SERVER_ROOT}/deposit-pt`;

    fetch(url, {
      method: 'POST',
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(session) {
        return stripe.redirectToCheckout({ sessionId: session.id });
      })
      .then(function(result) {
        if (result.error) {
          alert(result.error.message);
        }
      })
      .catch(function(error) {
        console.error('Error:', error);
      });
  }

  const handleClick = (e) => {
    e.preventDefault()
    fireGTMEvent();
    setLoading(true)
    checkOut();
  }

  return (
    <PSButton className="ps-btn-main"
      style={loading ? {...loadingStyles} : null} 
      onClick={handleClick}>
      {loading ? 'Redirecting...' : text}
    </PSButton>
  )
}

export default Button;

