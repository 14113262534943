// use `content-index.js` at launch
import React from 'react'
import styled from 'styled-components';
import { Col } from 'react-bootstrap';

const ImageBox = styled(Col)`
  position: relative;
  max-width: 1000px;
  margin: 0;
  padding: 20px;

  &::before {
    position: absolute;
    background: #000;
    left: 0px;
    top: 35px;
    width: 92%;
    height: 87%;
    content: "";
    display: block;
    z-index: 1;
  }

  .cover {
    position: relative;
    z-index: 10;
    background: #fff;
    border: 7px solid #000;
    text-align: center;
    
    img {
      width: 100%;
      margin: 0;
    }
    
    p {
      margin: 0 auto;
    }

    .subtext-launch {
      font-size: 18px;
      font-style: italic;
    }
  }

  h2 {
    font-size: 50px;
    font-weight: 800;
    padding: 10px 0 20px;
    color: #252b33;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
  }
`

const BrandBox = ({image, width, children}) => {
  const renderImageOrChildren = () => {
    if (image) {
      return <img src={image} alt=''></img>;
    } else {
      return {...children}
    }
  }
  return (
    <ImageBox md={width}>
      <div className="cover">
        {renderImageOrChildren()}
      </div>
    </ImageBox>
  )
}

export default BrandBox;
