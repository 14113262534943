import React from 'react'
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

const StyledContainer = styled(Container)`
  max-width: 1000px;
`

const HeroTextBox = styled(Col)`
  padding-left: 40px;

  h1 {
    padding-bottom: 20px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
  }

  .subtext-launch {
    font-size: 18px;
    font-style: italic;
  }
`

const ImageBox = styled(Col)`
  img {
    box-shadow: -20px 20px var(--black);
    border: 5px solid var(--black);
  }
  position: static;
`

const HeroRight = ({image, title, subtitle, subtext, children}) => {
  return (
    <StyledContainer>
      <Row>
        <ImageBox md={5}>
          <div>
            <img src={image} alt=''></img>
          </div>
        </ImageBox>
        <HeroTextBox md={7}>
          <h1>{title}</h1>
          {subtitle ? <p>{subtitle}</p> : null}
          {subtext ? <p className='subtext-launch'>{subtext}</p> : null}
          {children}
        </HeroTextBox>
      </Row>
    </StyledContainer>
  )
}

export default HeroRight;
