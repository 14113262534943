import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import Hero from "../components/hero/hero-right"
import Layout from "../components/layouts/courseLandingLayout"
import Navbar from "../components/navbars/courseLandingNavbar"
import zoom from "../assets/images/zoom.png"
import Button from "../components/buttons/linkButton"
import CheckoutButton from "../components/buttons/checkoutButton"
import BrandBox from "../components/brandBox/brandBoxWidget"
import ReactGA from "react-ga"
import styled from "styled-components"
import { Row, Container, Col } from "react-bootstrap"
import ApplyBox from "../components/applyBox"
import courseData from "../utils/course-data"
import moment from "moment"
import Capacity from "../components/capacity"
import DepositForm from "../components/forms/depositForm"
import PSModal from "../components/modal"

const NextStepsSection = styled.section`
  background: var(--black);
  margin-top: 50px;
  padding-top: 30px;

  p,
  h1,
  h3 {
    color: #fff;
  }

  .step-box {
    padding: 15px 0 15px 0;

    p {
      padding-top: 10px;
      font-size: 16px;
    }

    ul li {
      color: #fff;
      margin-left: 25px;
      font-family: "roboto-mono";
    }
  }
`

const PayDepositSection = styled.section`
  background: var(--yellow);

  padding-top: 30px;
  padding-bottom: 40px;

  .brandBoxTitle {
    padding-bottom: 0;
  }

  .brandBoxSubTitle {
    text-align: left;
    padding-left: 15px;
    padding-bottom: 10px;
  }

  .depositDeadline {
    padding-bottom: 0;
  }

  .depositExplanation {
    font-style: italic;
    opacity: 0.8;
  }
`

const ProgramCol = styled(Col)`
  padding-bottom: 50px;
`
const ProgramCard = styled.div`
  p {
    font-size: 14px;
    text-align: left;
    padding-left: 15px;
  }

  h3 {
    text-align: left;
    padding: 15px;
  }
`

const DepositDiv = styled.div`
  padding-top: 120px;
  height: 100%;
  font-family: sans-serif;

  .cta-hold {
    margin-right: 20px;
  }
`

const MaxWidthContainer = styled(Container)`
  max-width: 1000px;
`

const CenterButton = styled.div`
  display: block;
  margin: 0 auto;
  width: 200px;
`

const CapacityDiv = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: -20px 20px var(--black);
  border: 5px solid var(--black);
`

const Deposit = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const [showDepo, setShowDepo] = useState(false)

  const handleCloseDepo = () => setShowDepo(false)
  const handleShowDepo = e => setShowDepo(true)

  const handleCTAClick = e => {
    console.log(e)
  }

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "deposit",
    })
  }

  const renderNextDepositDeadline = course => {
    return courseData.returnNextDepositDate(course.deposit_deadlines)
  }

  const renderFirstDepositDeadline = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return moment(startDateObject.deposit_deadlines[0].date).format(
      "MMMM D, YYYY"
    )
  }

  const renderFirstDepositAmount = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return startDateObject.deposit_deadlines[0].discount
  }

  const renderNextApplicationDeadline = () => {
    const startDateObject = courseData.returnByStartDateObject(
      "upcomingCourses",
      courseData.nextStartDate("upcomingCourses").format("YYYY-MM-DD")
    )

    return moment(startDateObject.applicationDeadline).format("MMMM D, YYYY")
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Navbar location={location} />
      <PSModal
        handleClose={handleCloseDepo}
        handleShow={handleShowDepo}
        show={showDepo}
      >
        <DepositForm
          close={handleCloseDepo}
          align="center"
          price={`${process.env.GATSBY_PREP_PRICE}`}
          location={location}
        />
      </PSModal>
      <DepositDiv>
        <Hero
          image={zoom}
          title={"Hold Your Spot_"}
          subtitle={`Reserve 1 of 10 Full-time or 10 Part-time spots for an upcoming program while you attempt the admissions code challenge.`}
        >
          <a href="#pay-deposit">
            <Button
              classToAdd={"cta-hold"}
              fireGTMEvent={fireGTMEvent}
              handleCTAClick={handleCTAClick}
              text={"Hold My Spot"}
            />
          </a>
        </Hero>
        <NextStepsSection>
          <MaxWidthContainer fluid>
            <Row>
              <Col md={12}>
                <h1>Next Steps</h1>
                <p>How to get in!</p>
                <div className="step-box">
                  <h3>
                    Step 1: Hold Your Spot (and maybe get a discount) with Early
                    Deposit
                  </h3>
                  <p>
                    Our programs are capped at just 20 students (10 full-time
                    and 10 part-time). To reserve your spot you can pay a $986
                    Deposit (as long as there is space in the cohort).
                    <br></br>
                    <br></br>
                    Depending on how early you pay the deposit, all prospective
                    students are eligible for a $500 or $1,000 discount ($1,400
                    if you complete{" "}
                    <a href="https://dev30.xyz/" target="_blank">
                      dev30
                    </a>
                    ). The puprose of the discount is to incentivize prospective
                    students to lock in their spot early so we can start
                    investing in you far before the cohort begins to prepare you
                    for the code challenge and starting the program off strong.
                    <br></br>
                    <br></br>
                    For more information on the deposit's terms, see{" "}
                    <a
                      href="https://f.hubspotusercontent30.net/hubfs/8389530/deposit-confirmation.pdf"
                      target="_blank"
                    >
                      this document
                    </a>
                    .
                  </p>
                </div>
                <div className="step-box">
                  <h3>Step 2: Apply</h3>
                  <p>
                    At any time, you can <Link to="/apply">apply</Link>! But
                    keep in mind that until pay the deposit, your spot will not
                    be reserved.
                  </p>
                </div>
                <div className="step-box">
                  <h3>Step 3: Code Challenge & Prep Course</h3>
                  <p>
                    After applying, you will receive a code challenge to
                    complete at home. This code challenge will test you on the
                    basics of JavaScript. If you successfully complete the code
                    challenge, you will be admitted to the program.
                  </p>

                  <p>
                    {" "}
                    If you do not successfully complete the code challenge, we
                    will ask you to take our JavaScript Prep Course. You can do
                    that one of 2 ways:
                  </p>

                  <ul>
                    <li>
                      Schedule and time permitting, you can enroll in{" "}
                      <a href="https://dev30.xyz/" target="_blank">
                        dev30
                      </a>
                      . The fees for dev30 will be applied to Parsity
                    </li>
                    <li>
                      If you pay the deposit, you will be automatically invited
                      to our{" "}
                      <a
                        href="https://parsity.io/self-paced-prep"
                        target="_blank"
                      >
                        Self Paced JavaScript Course
                      </a>{" "}
                      at no cost.
                    </li>
                  </ul>
                </div>
                <div className="step-box">
                  <h3>Step 4: Pre-Work</h3>
                  <p>
                    Once you're accepted to the program, you'll gain access to
                    our Pre-Work which covers the basics of HTML, CSS, Git and
                    other coding topics, in addition to included courses on "How
                    to Focus When Learning to Code", which are intended to help
                    you make the most out of time at Parsity.
                  </p>
                </div>
                <div className="step-box">
                  <h3>Step 5: Begin!</h3>
                  <p>
                    At last, you're ready to start! You'll receive invites to
                    Slack groups and Zoom rooms to interact with staff, mentors,
                    and other students, and a questionnaire which we'll use to
                    help pair you with your first mentor.
                  </p>
                </div>
              </Col>
            </Row>
          </MaxWidthContainer>
        </NextStepsSection>
        <PayDepositSection id="pay-deposit">
          <MaxWidthContainer fluid>
            <Row>
              <Col md={12}>
                <h1>Take the First Step</h1>
                <p>
                  Click "Pay Deposit" button below to pay your deposit for an
                  upcoming cohort.
                </p>
                <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                  <Button
                    classToAdd="dates-cta"
                    fireGTMEvent={fireGTMEvent}
                    handleCTAClick={e => handleShowDepo(e)}
                    text={"Pay Deposit"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              {courseData.upcomingCourses.map(function (course, i) {
                return (
                  <>
                    <ProgramCol md={6}>
                      <BrandBox className="program-card" width={6}>
                        <ProgramCard>
                          <h3 className="brandBoxTitle">
                            {moment(course.startDate).format("MMMM D, YYYY")}{" "}
                          </h3>

                          <h5 className="brandBoxSubTitle">{course.program}</h5>

                          <p className="">
                            <strong>Start Date:</strong>{" "}
                            {courseData
                              .nextStartDate("upcomingCourses")
                              .format("MMMM D, YYYY")}
                          </p>

                          <p>
                            <strong>Tuition:</strong>$9,200 (
                            <i>
                              we partner with{" "}
                              <a
                                href="https://bootcamp.ascentfunding.com/"
                                target="_blank"
                              >
                                Ascent
                              </a>
                              for financing
                            </i>
                            )
                          </p>

                          <p className="depositDeadline">
                            <strong>Deposit Deadline 1:</strong>{" "}
                            {moment(course.deposit_deadlines[0].date).format(
                              "MMMM D, YYYY"
                            )}{" "}
                          </p>
                          <p className="depositExplanation">
                            Pay by date for $1,000 tuition discount ($1,400 if
                            you complete{" "}
                            <a href="https://dev30.xyz/" target="_blank">
                              dev30
                            </a>
                            ).
                          </p>

                          <p className="depositDeadline">
                            <strong>Deposit Deadline 2:</strong>{" "}
                            {moment(course.deposit_deadlines[1].date).format(
                              "MMMM D, YYYY"
                            )}{" "}
                          </p>
                          <p className="depositExplanation">
                            Pay by date for $500 tuition discount.
                          </p>

                          {/* <Capacity course={course} /> */}
                        </ProgramCard>
                      </BrandBox>
                    </ProgramCol>
                  </>
                )
              })}
            </Row>
          </MaxWidthContainer>
        </PayDepositSection>
      </DepositDiv>
    </Layout>
  )
}

export default Deposit

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
